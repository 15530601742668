import React from "react";
import Section from "../../../section";
import { graphql } from "gatsby";
import Carousel from "../../../carousel";
import HtmlContent from "../../../html-content";
import ButtonPrimary from "../../../button-primary";

const CoachingSection = ({
  coaching: { title, subtitle, button_title, images, text_1, text_2 },
}) => {
  return (
    <Section title={title} subtitle={subtitle}>
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2 order-md-3 mt-md-4">
          <Carousel id="coachingSectionCarousel" images={images} />
        </div>
        <div className="col col-md-6 mt-4 mt-md-1">
          {text_1 && <HtmlContent content={text_1.data} />}
        </div>

        <ButtonPrimary title={button_title} to="/spiritual-awakening-coaching"/>

        <div className="col col-md-6 mt-3 mt-md-1">
          {text_2 && <HtmlContent content={text_2.data} />}
        </div>
      </div>
    </Section>
  );
};

export default CoachingSection;

export const query = graphql`
  fragment CoachingSectionFragment on STRAPI_HOME_PAGE {
    coaching {
      title
      button_title
      images {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              height: 600
              sizes: "(max-width: 576px) 540px, (max-width: 768px) 720px, (max-width: 992px) 960px, (max-width: 1200px) 1140px, 1320px"
            )
          }
        }
        alternativeText
      }
      subtitle
      text_1 {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      text_2 {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
