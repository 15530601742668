import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const SocialMediaLinks = () => {
  const { strapiContact } = useStaticQuery(graphql`
    query {
      strapiContact {
        social_medias {
          platform
          url
        }
      }
    }
  `);

  if (!strapiContact) return null;

  const { social_medias } = strapiContact;

  return (
    <div className="row">
      <div className="col d-flex justify-content-center">
        {social_medias?.map(({ url, platform }, index) => (
          <a
            key={platform}
            target="_blank"
            href={url}
            className={index !== 0 ? "ms-4" : ""}
            rel="noreferrer"
          >
            {platform && (
              <svg className="bi" width="3rem" height="3rem">
                <use xlinkHref={`#${platform.toLowerCase()}`} />
              </svg>
            )}
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaLinks;
