import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const ButtonSecondary = ({ title, to }) => {
  return (
    <div className="c-btn-secondary d-grid col-8 offset-2 order-md-4 mt-md-3">
      <Link
        className="btn btn-outline-dark px-4 py-2 btn-md-lg fs-4 text-md-uppercase"
        to={to}
      >
        {title}
      </Link>
    </div>
  );
};

export default ButtonSecondary;
