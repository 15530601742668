import { Link, graphql } from "gatsby";
import React from "react";
import Section from "../../../section";
import Carousel from "../../../carousel";
import HtmlContent from "../../../html-content";
import ButtonSecondary from "../../../button-secondary";

const ExploreWithMeSection = ({
  explore_with_me: { title, subtitle, button_title, text_1, text_2, images },
}) => {
  return (
    <Section title={title} subtitle={subtitle}>
      <div className="row">
        <div className="col-lg-6 mt-md-3">
          <div className="row">
            <Carousel
              id="exploreWithMeCarousel"
              images={images}
              animationHandler="fade"
            />
          </div>
        </div>
        <div className="col-lg-6 mt-3 ">
          <HtmlContent content={text_1} />
          <div className="d-none d-md-block">
            <HtmlContent content={text_2} />
          </div>
        </div>
      </div>
      <div className="row mt-md-4">
        <div className="col text-center">
          <ButtonSecondary title={button_title} to="/explore-with-me" />
        </div>
      </div>
    </Section>
  );
};

export const query = graphql`
  fragment ExploreWithMeSectionFragment on STRAPI_HOME_PAGE {
    explore_with_me {
      title
      subtitle
      button_title
      text_1 {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      text_2 {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      images {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              sizes: "(max-width: 576px) 540px, (max-width: 768px) 720px, (max-width: 992px) 960px, (max-width: 1200px) 1140px, 1320px"
            )
          }
        }
      }
    }
  }
`;
export default ExploreWithMeSection;
