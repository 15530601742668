import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Banner from "../components/banner";
import IntroSection from "../components/home/sections/intro";
import HtmlContent from "../components/html-content";
import AboutMeSection from "../components/home/sections/about-me";
import CoachingSection from "../components/home/sections/coaching";
import ChanneledHandwrittenLettersSection from "../components/home/sections/handwritten-letter";
import ExploreWithMeSection from "../components/home/sections/explore-with-me";
import ContactSection from "../components/home/sections/contact";

// eslint-disable-next-line
import * as PageSeoFragment from "../fragments/pageSeo";
import wrapWithLayout from "../components/layout/wrapWithLayout";

const IndexPage = wrapWithLayout(({ data }) => {
  const { strapiHomePage } = data;
  const banner = strapiHomePage?.banner;

  return (
    <>
      {banner && <Banner {...banner} />}
      <main className="container">
        <div className="mt-5">
          <IntroSection {...strapiHomePage} />
        </div>
        <AboutMeSection {...strapiHomePage} />
        <CoachingSection {...strapiHomePage} />
        <ChanneledHandwrittenLettersSection {...strapiHomePage} />
        <ExploreWithMeSection {...strapiHomePage} />
        <ContactSection {...strapiHomePage} />
      </main>
    </>
  );
});

export const query = graphql`
  query {
    strapiHomePage {
      seo {
        ...PageSeoFragment
      }
      banner {
        ...BannerFragment
      }
      ...IntroSectionFragment
      ...AboutMeSectionFragment
      ...CoachingSectionFragment
      ...ChanneledHandwrittenLettersSectionFragment
      ...ExploreWithMeSectionFragment
      ...ContactSectionFragment
    }
  }
`;

export default IndexPage;
