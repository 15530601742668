import { graphql } from "gatsby";
import React from "react";
import "./style.scss";
import Quote from "../../../quote/quote";

const IntroSection = ({ intro_text }) => {
  return <Quote text={intro_text} />;
};

export default IntroSection;

export const query = graphql`
  fragment IntroSectionFragment on STRAPI_HOME_PAGE {
    intro_text
  }
`;
