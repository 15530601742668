import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import HtmlContent from "../../../html-content";
import Section from "../../../section";
import Carousel from "../../../carousel";

const AboutMeSection = ({ about_me: { text_1, text_2, title, images } }) => {
  const hasText = text_1 || text_2;

  return (
    <Section title={title} alignTitle="end">
      <div className="row">
        {hasText && (
          <div className="col-md-8 text-end">
            {text_1 && <HtmlContent content={text_1.data} />}
            <div className="d-none d-md-block">
              {text_2 && <HtmlContent content={text_2.data} />}
            </div>
          </div>
        )}
        <div className="col-md-4 text-md-end">
          {images && <Carousel id={"about-me-carousel"} images={images} />}
        </div>
        {text_2 && (
          <div className="d-md-none col text-end mt-4">
            <HtmlContent content={text_2.data} />
          </div>
        )}
      </div>
    </Section>
  );
};

export default AboutMeSection;

export const query = graphql`
  fragment AboutMeSectionFragment on STRAPI_HOME_PAGE {
    about_me {
      text_1 {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      text_2 {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      title
      subtitle
      images {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, height: 535)
          }
        }
        alternativeText
      }
    }
  }
`;
