import { graphql } from "gatsby";
import React from "react";
import Section from "../../../section";
import HtmlContent from "../../../html-content";
import "./style.scss";
import ButtonPrimary from "../../../button-primary";
import Quote from "../../../quote/quote";

const ChanneledHandwrittenLettersSection = ({
  channeled_handwritten_letter: { title, subtitle, button_title, text_1 },
}) => {
  const text = text_1?.data?.text_1;

  return (
    <Section
      className="c-channeled-handwritten-letter-section"
      alignTitle="center"
      title={title}
      subtitle={subtitle}
    >
      <div className="c-channeled-handwritten-letter-section__container">
        {text && (
          <div className={"mt-2"}>
            <Quote text={text} fs="2" />
          </div>
        )}
        <div className="text-center mt-4">
          <div className="d-md-grid col-md-6 offset-md-3">
            <ButtonPrimary to="/handwritten-letter" title={button_title} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export const query = graphql`
  fragment ChanneledHandwrittenLettersSectionFragment on STRAPI_HOME_PAGE {
    channeled_handwritten_letter {
      title
      subtitle
      button_title
      text_1 {
        data {
          text_1
        }
      }
    }
  }
`;

export default ChanneledHandwrittenLettersSection;
