import React from "react";
import "./style.scss";

const Quote = ({ text, fs = "4" }) => {
  return (
    <blockquote className={`c-quote text-justify text-center px-2 fs-${fs}`}>
      {text}
    </blockquote>
  );
};

export default Quote;
